.flexBlock{
    margin-top: 40px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-around; 
    /*align-items: center;*/
}
.group{
    margin-bottom: 20px;
}

.groupElem{
    display: flex;
    justify-self: start;
    align-items: center;
    margin: 10px;
}

.groupElem div{
    margin-right: 10px;
}

.textAreaWidth, .inputText, .selectWidth{
    width: 490px;
}

.bottonBlock{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.bankAccountElements{
    display: flex;
    flex-direction: column;
    /*width: 100%;*/
    width: calc(620px - 20px);
}

.bankAccountElement{
    width: 100%;
    display: flex;
    justify-content: space-around;
    border: 1px solid #c5c5c5;
    padding: 3px;
    margin: 2px; 
}

.bankAccountTitle{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
}

.inputMax{
    /*width: 100%;*/
    width: 490px;
}

.inputMaxBank{
    /*width: 100%;*/
    width: 616px;
}

.titleValue{
    margin-left: 10px;
}

.helper{
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}

.pseudoCheckboxOn{
    width: 15px;
    height: 15px;
    border: 1px solid #000;
}
.pseudoCheckboxOff{
    width: 15px;
    height: 15px;
    border: 1px solid #000;
    background-color: black;
}

.valuta{
    width: 160px;
}

.valutaInput{
    width: 100%;
}

.helperText{
    width: 200px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.38);
}

.hoverOptions:hover{
    background-color: cornflowerblue;
    cursor: pointer;
}


.typeWrap{
    border-radius: 4px;
    background-color: #c5c5c5;
    padding: 24px;
}