.blockBidLot{
    background: linear-gradient(#eee,#fff);
    margin-top: 25px;
}

.blockBidLot_title{
    font: 600 30px/30px "robotothin",Arial,sans-serif;
    color: #000;
    text-transform: uppercase;
    padding-left: 30px;
    padding-top: 30px;
}

.messageBlock{
    padding: 15px 25px;
    text-align: center;
    border: 1px solid #ffd200;
    font: 400 13px/18px "robotolight",Arial,sans-serif;
    margin-top: 25px;
}

.messageBlockAuth{
    margin-bottom: 25px;
}

.bidLotCost{
    display: flex;
    justify-content: left;
    text-align: left;
    margin-top: 20px;
}

.bidLotCost_title{
    margin-left: 30px;
    margin-right: 60px;
    color: #6d6d6d;
}

.bidLotFile_titleWrap{
    display: flex;
    justify-content: left;
}

.bidLotFile_title{
    margin-left: 30px;
    margin-right: 630px;
    text-align: left;
    font-weight: 500;
}
.bidLotFile_helpText{
    color: #6d6d6d;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
}

.bidLotCost_input{
    color: #6d6d6d;
    font-size: 12px;
}

.bidLotCost_input input{
    width: 400px;
    border: 1px solid #5d5d5d;
    font-size: 14px;
    padding: 3px;
}

.inpMargin{
    margin-left: 52px;
}

.formBorder{
    width: 610px;
    margin-top: 20px;
    margin-left: 30px;
    border-top: 1px solid #5d5d5d;
}

.messageBlock a{
    color: #6d6d6d;
}

a{
    /*color: #585858;*/
    text-decoration: underline;
    cursor: pointer;
}

.inputNumber::-webkit-outer-spin-button,
.inputNumber::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.input__wrapper {
    width: 100%;
    position: relative;
    /*margin: 15px 0;
    text-align: center;*/
}
   
.input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}
   
   
.input__file_button_text {
    line-height: 1;
    padding: 20px;
    /*border: 1px solid #000;*/
    border-radius: 5px;
}
   
.input__file_button {
    /*width: 100%;
    max-width: 280px;
    height: 60px;
    background: grey;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 700;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: center;
    cursor: pointer;*/
    width: 100%;
    max-width: 280px;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    cursor: pointer;
    margin-left: 10px;
    text-decoration: underline;
}

.addFileForm{
    text-align: center;
}

.elemCheckbox{
    margin-left: 30px;
    text-align: left;
}

.selectType{
    margin-left: 86px;
    width: 408px;
    padding: 3px;
    margin-bottom: 30px;
}

.borderLineFile{
    width: 1140px;
    margin: auto;
    height: 1px;
    background-color: #5d5d5d;
    margin-top: 30px;
    margin-bottom: 30px;
}

.button{
    width: 400px;
    padding: 15px 25px;
    background-color: #ffd200;
    border: 1px solid #ffd200;
    font: 600 13px/18px "robotolight",Arial,sans-serif;
    text-transform: uppercase;
    text-align: left;
    cursor: pointer;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 25px;
}

.button_disabled{
    background-color: #c5c5c5;
    border: 1px solid #c5c5c5;
    cursor: default;
}

.titlelAddDoc{
    text-align: left;
    margin-left: 30px;
    margin-top: 10px;
    font-weight: 600;
    text-transform: uppercase;
}

.listDocument{
    display: flex;
    justify-content: space-between;
}

.bidLotCost_title_notMargin{
    margin-right: 0px;
    text-align: left !important;
}

.deleteDoc{
    cursor: pointer;
}

.buttonBlock{
    display: flex;
    justify-content: space-around;
}

.buttonBlock div{
    width: 200px;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}

.chekbox{
    padding-left: 30px;
    display: flex;
}
.pseudoCheckboxOn{
    width: 15px;
    height: 15px;
    border: 1px solid #000;
    margin-right: 15px;
}
.pseudoCheckboxOff{
    width: 15px;
    height: 15px;
    border: 1px solid #000;
    /*background-color: black;*/
    background-image: url('./checkbox.png');
    background-size: cover;
    margin-right: 15px;
}