@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/* info BLOCK */
.infoBlock {
    font-family: 'Segoe UI';
    width: 732px;
    height: auto;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 0 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}
.infoBlock div:last-child{
    margin-bottom:0;
}
.title_wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 15px;
    font-size: 18px;
    font-weight: 600;
}